.carouselContainer {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 !important;
}

.card {
  background-color: #f0f0f0 !important;
  padding: 20px;
  text-align: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #b3b3b3;
  margin: 0 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.activeDot {
  background-color: #ff8100;
}

.carouselContainer .react-multi-carousel-track {
  margin-bottom: 40px;
}

.customArrow {
  display: none;
}
