.homeContainer {
  padding: 50px 30px !important;
  margin: 0 !important;
  width: 100% !important;
  background-color: #f7f7f7 !important;
  max-width: none !important;
  min-height: calc(100vh - 75px);
}

.officeImage {
  box-shadow: 12px 12px 0px #cfdcdf, 24px 24px 0px #f5be91,
    40px 40px 15px #cdcbcb;
  margin-left: 40px;
  height: -webkit-fill-available;
}

.readMore {
  text-transform: none !important;
  border-radius: 50px !important;
  font-size: 1rem !important;
  padding: 13px 25px !important;
  margin-top: 20px !important;
  background-color: #ee8043 !important;
  transition: background-color 0.5s ease !important;
}

.readMore:hover {
  background-color: #158ca1 !important;
}

.motto {
  position: relative;
  background: url("../../images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 60px !important;
  text-align: -webkit-center;
  width: calc(100% + 60px) !important;
  margin-left: -30px !important;
  margin-right: -30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-position: center center;
  max-width: none !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  max-width: none !important;
}

.text {
  z-index: 10;
  color: white;
  position: relative;
  font-weight: bold !important;
  line-height: 3rem !important;
}

.newsContainer {
  margin: 50px 0px;
}

.newsTitle {
  font-weight: bold !important;
  text-align: center;
  color: #f48900;
}

.cardHeading {
  color: initial !important;
  transition: color 0.5s ease;
  font-weight: bold !important;
}

.cardHeading:hover {
  color: #f75800 !important;
}

.cardImage {
  position: relative;
}

.cardOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: none !important;
  transition: background-color 0.4s ease;
}

.cardOverlay:hover {
  background-color: rgb(217 217 217 / 42%);
}

@media only screen and (max-width: 600px) {
  .officeImage {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .officeImage {
    width: 75% !important;
  }
}
