.contactContainer {
  min-height: calc(100vh - 100px);
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  max-width: none !important;
  background-color: #f5f5f5 !important;
}

.titleContainer {
  background: url("../../images/medicine.jpg");
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 20px 40px 60px !important;
  background-position: center center;
  max-width: none !important;
}

.contactOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, #ffb239d4, #ffc3621c);
  max-width: none !important;
}

.contactTitle {
  z-index: 10;
  color: white;
  position: relative;
  font-weight: 600 !important;
}

.contact {
  max-width: none !important;
}

.contactIcons {
  color: #ff8e1d;
}

.iconsTitle {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  color: #008da0;
}

.formContainer {
  padding: 40px;
  background-color: #007c92;
  height: 100%;
  color: white;
}

.inputField::before {
  border-bottom: none !important;
}

.inputField:hover {
  border-bottom: 1px solid #c9c9c9 !important;
}

.inputField::after {
  border-bottom: 1px solid #ff690c !important;
}

.formSubmit {
  width: 100%;
  margin-top: 35px !important;
  border-radius: 35px !important;
  padding: 12px !important;
  background-color: white !important;
  color: #007c92 !important;
  text-transform: capitalize !important;
  font-size: 0.93rem !important;
  font-weight: bold !important;
  border: 1px solid white !important;
  transition: background-color 0.5s ease;
}

.formSubmit:hover {
  background-color: transparent !important;
  color: white !important;
}
