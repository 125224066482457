.medicineContainer {
  min-height: calc(100vh - 150px);
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  background-color: #f5f5f5 !important;
  max-width: none !important;
  display: flex !important;
  flex-direction: column;
}

.heightClass {
  min-height: calc(100vh - 350px);
}

.medicineTitleContainer {
  background: url("../../images/medicine-banner.jpg");
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 20px 40px 60px !important;
  background-position: center center;
  max-width: none !important;
}

.medicineOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, #ffb239d4, #ffc3621c);
  max-width: none !important;
}

.medicineTitle {
  z-index: 10;
  color: white;
  position: relative;
  font-weight: 600 !important;
}
