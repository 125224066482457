.appbar {
  min-height: 75px;
  justify-content: center;
  background-image: linear-gradient(to right, #c8eaf1, #008aa5);
  /* linear-gradient(
    to right,
    #c8eaf1,
    #ff9c5f
  ); */
  /* linear-gradient(
    to right,
    #eee4d4,
    #ff8d47
  ); */
  /* position: relative !important; */
}

.nav {
  display: flex;
  justify-content: space-between;
}

.nav-links {
  text-transform: none !important;
  font-size: 1rem !important;
  color: #ffffff !important;
}

.nav-links:hover {
  color: #c4edef !important;
}
