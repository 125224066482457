.productDot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #b3b3b3;
  margin: 0 4px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.activeProduct {
  background-color: #24242485;
}

.product::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.2px; /* adjust height for shadow size */
  background-color: rgba(0, 0, 0, 0.2);
}
