.footer {
  padding: 24px 32px;
  background-image: linear-gradient(to right, #81d6e7, #097f95);
  color: white;
}

.icons {
  min-width: 35px;
  color: white !important;
}

.mail {
  color: inherit !important;
}

.mail:hover {
  color: #c4edef !important;
}

.copyright {
  background-color: #086070;
  min-height: 60px;
  max-width: none !important;
  padding: 24px !important;
  text-align: center;
}

.socialIcons {
  margin-right: 15px;
}

.socialIcons:hover {
  transform: scale(1.07);
}
