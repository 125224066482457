html {
  height: 100vh;
}

#root {
  min-height: 100vh;
}

@media screen and (max-width: 320px) {
  #root {
    width: 320px;
    overflow-x: scroll;
  }
}
